<template>
  <div class="row">
    <div class="col-md-4">
      <div class="card card-custom gutter-b example example-compact">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">Basic Information</h3>
          </div>
        </div>
        <div class="card-body">
          <b-form v-if="show">
            <b-form-group id="input-group-0" label="Id:" label-for="input-0">
              <b-form-input
                id="input-0"
                v-model="study.id"
                disabled
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-1"
              label="Name:"
              label-for="input-1"
              description="The name of the clinical study"
            >
              <b-form-input
                id="input-1"
                v-model="study.name"
                :disabled="user.userTypeId != 4"
                required
                placeholder="Enter name"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Description:"
              label-for="input-2"
              description="Description of the clinical study"
            >
              <b-form-textarea
                id="input-2"
                v-model="study.description"
                placeholder="Enter description"
                :disabled="user.userTypeId != 4"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group
              id="input-group-3"
              label="Study Status:"
              label-for="input-3"
            >
              <b-form-select
                id="input-3"
                v-model="study.status"
                :options="status_options"
                :disabled="user.userTypeId != 4"
                required
              ></b-form-select>
            </b-form-group>

            <b-button
              @click="onSubmit"
              variant="primary"
              v-if="user.userTypeId == 4"
              >Update</b-button
            >
            <b-button
              v-b-modal.modal-delete-study-confirmation
              variant="danger"
              v-if="user.userTypeId == 4"
              >Delete</b-button
            >
          </b-form>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="card card-custom gutter-b example example-compact">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">Groups</h3>
          </div>
          <div class="card-toolbar">
            <div class="justify-content-center" v-if="user.userTypeId == 4">
              <b-button v-b-modal.modal-add-new-group variant="info"
                >New</b-button
              >
            </div>
          </div>
        </div>
        <div class="card-body">
          <b-table
            hover
            bordered
            v-if="study.groups"
            :items="study.groups"
            :fields="table_fields"
            @row-clicked="(item, index, event) => rowClickHandler(item)"
          >
            <template #cell(createdAt)="data">
                  {{ formatDate(data.item.createdAt) }}
                </template>
          </b-table>
        </div>
      </div>
    </div>
    <!-- begin::Modal -->
    <b-modal id="modal-delete-study-confirmation" title="Delete Study">
      <h4>Are you sure you want to delete this study?</h4>
      <p>This action cannot be undone</p>
      <template #modal-footer="{ }">
        <b-button size="sm" @click="onCancelDeleteStudy"> Cancel </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="danger" @click="onConfirmDeleteStudy">
          Confirm
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
    <!-- begin::Modal -->
    <b-modal id="modal-add-new-group" title="Add new group for this study">
      <b-form @submit="onNewGroup">
        <b-form-group id="input-group-1" label="Name:" label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="modal_new_group_data.name"
            required
            placeholder="Enter name"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          label="Description:"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            v-model="modal_new_group_data.description"
            required
            placeholder="Enter description"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ }">
        <b-button size="sm" variant="danger" @click="onCancelNewGroup">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="primary" @click="onNewGroup">
          Submit
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "study",
  data() {
    return {
      error_msg: null,
      modal_new_group_data: {
        name: null,
        description: null,
        studyId: null,
      },
      status_options: [
        { text: "Select One", value: null },
        { text: "Active", value: true },
        { text: "Deactivated", value: false },
      ],
      show: true,
      study: {
        id: null,
        name: "",
        description: "",
        status: null,
      },
      table_fields: [
        { key: "id", label: "Id" },
        { key: "name", label: "Name" },
        { key: "description", label: "Description" },
        { key: "createdAt", label: "Created At" },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  watch: {
    $route() {
      this.refresh();
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Study" }]);
    this.refresh();
  },
  methods: {
    refresh() {
      ApiService.get("/studies/" + this.$route.params.id).then(({ data }) => {
        this.study = data.data;
        this.modal_new_group_data.studyId = this.study.id;
      });
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // +1 because months are 0-indexed
      const day = String(date.getDate()).padStart(2, "0");
      return `${day}-${month}-${year}`;
    },    
    onSubmit() {
      ApiService.put("/studies/" + this.$route.params.id, this.study).then(
        ({ data }) => {
          this.$store.commit("UPDATE_STUDY", data.data);
          this.refresh();
        }
      );
      alert("Succesfully updated study details");
    },
    onCancelDeleteStudy(evt) {
      evt.preventDefault();
      this.$bvModal.hide("modal-delete-study-confirmation");
    },
    onConfirmDeleteStudy() {
      ApiService.delete("/studies/" + this.$route.params.id).then(() => {
        this.$store.commit("DELETE_STUDY", this.study.id);
        this.$router.push("/");
      });
    },
    onNewGroup(evt) {
      evt.preventDefault();
      ApiService.post("/groups", this.modal_new_group_data)
        .then(({ data }) => {
          this.$bvModal.hide("modal-add-new-group");
          this.$store.commit("ADD_GROUP", data.data);
          this.refresh();
        })
        // TODO: give more suitable error messages (dont give too much detail of backend)
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            this.error_msg = error.response.data.message;
          } else {
            this.error_msg = "An error occurred. Please try again later.";
          }
          this.$refs["error-modal"].show(); // Show the error popup
        });
    },
    onCancelNewGroup(evt) {
      evt.preventDefault();
      this.modal_new_group_data.name = "";
      this.$bvModal.hide("modal-add-new-group");
    },
    rowClickHandler(item) {
      // TODO: better access control here
      if (this.user.userTypeId === 4) {
        this.$router.push("/group/" + item.id);
      } else {
        alert(
          "Current user does not have admin permission to access all group. Please use the side menu on the left to access restricted patient data."
        );
      }
    },
  },
};
</script>
